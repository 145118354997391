h1{
  margin: 2rem 0;
  font-size: rem-calc(30);
}
form{
  padding: 0 0 2rem;
}
.content-wrapper{
  padding: 0 1rem;
}

#UserLocations {
  height: 100%;
  min-height: 300px;
  max-height: 400px;
}

button{
  &.primary{
    color: #fff !important;
  }
  padding: 0.75rem !important;
  font-size: rem-calc(1rem) !important;
  font-style: normal;
}